.Menu {
  position: fixed;
  z-index: 100;
  width: 100%;
}

.Closed {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.Open {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ThemeToggle {
  left: var(--s_m_menu_h_margin);
  top: 62px;
  cursor: pointer;
  position: fixed;
  transition: var(--s_m_hover_transition);
  color: var(--s_m_font_color);
  fill: var(--s_m_font_color);
  transition-property: color, fill;
  filter: var(--s_m_menu_drop_shadow_fliter);
}

.ThemeToggle > * {
  transition: var(--s_m_hover_transition);
  transition-property: color, fill;
  color: var(--s_m_font_color);
  fill: var(--s_m_font_color);
}

.MenuBtn {
  right: var(--s_m_menu_h_margin);
  top: 62px;
  cursor: pointer;
  position: fixed;
  transition: var(--s_m_hover_transition);
  fill: var(--s_m_font_color);
  filter: var(--s_m_menu_drop_shadow_fliter);
}

.CloseBtn {
  cursor: pointer;
  top: 62px;
  right: var(--s_m_menu_h_margin);
  margin-top: -7px;
  z-index: 200;
  position: fixed;
  transition: var(--s_m_hover_transition);
  fill: var(--s_m_font_color);
  filter: var(--s_m_menu_drop_shadow_fliter);
}

.Header {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
}

.ToolBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  background-color: transparent;
  height: 100%;
  padding-top: 60px;
}

.Drawer {
  transition: all ease-in-out 0.7s;
  position: absolute;
}

.Open .Drawer {
  transform: translateY(0);
}

.Closed .Drawer {
  transform: translateY(calc((var(--s_m_menu_half_circle_h)) * -1 - 50vw));
}

.Links {
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Links > a {
  transition: var(--s_m_hover_transition);
  color: var(--s_m_font_color);
  text-decoration: none;
}

.Links > header {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  margin-bottom: 50px;
}

.LogoClosed {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  position: relative;
  top: -19px;
}

.LogoClosed > svg {
  transition: var(--s_m_hover_transition);
  fill: var(--s_m_font_color);
  height: auto;
  width: 303px;
}

.HalfCircle {
  z-index: 1;
  /* height: var(--s_m_menu_half_circle_h); */
  width: var(--s_m_menu_half_circle_w);
  min-width: 1200px;
  min-height: 900px;
  max-height: 110vh;
  position: relative;
  top: -5vw;
}

.SocialIcons {
  margin-top: 50px;
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .ThemeToggle:hover > * {
    color: var(--s_m_hover_color);
    fill: var(--s_m_hover_color);
  }

  .MenuBtn:hover {
    fill: var(--s_m_hover_color);
  }

  .CloseBtn:hover {
    fill: var(--s_m_hover_color);
  }

  .LogoClosed:hover > svg {
    fill: var(--s_m_hover_color);
  }
  .Links > a:hover {
    color: var(--s_m_hover_color);
  }
}

/* Only if mobile view */
@media only screen and (max-width: 1024px) {
  .ThemeToggle {
    top: 49px;
  }
  .MenuBtn {
    top: 55px;
  }
  .CloseBtn {
    top: 56px;
  }

  .Links > a {
  }

  .LogoClosed > svg {
    max-width: 40vw;
  }
  .HalfCircle {
    top: min(-10vh, -10vw);
  }

  .Closed .Drawer {
    transform: translateY(
      min(calc((var(--s_m_menu_half_circle_w)) * -1 - 20vh), -1000px)
    );
  }
}

@media only screen and (orientation: landscape) {
  .Closed .Drawer {
    transform: translateY(
      min(calc((var(--s_m_menu_half_circle_w)) * -1 - 20vh), -1000px)
    );
  }
}
