.AppBody {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--s_m_background_color);
  color: var(--s_m_font_color);
  transition: var(--s_m_theme_transition);
  transition-delay: 0;
}

.AppContent {
  height: auto;
}
