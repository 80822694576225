.Privacy {
  padding-top: 35px;
  padding-bottom: 150px;
}

.Logo {
  margin-left: calc(50% - 24px);
  margin-bottom: 20px;
  cursor: pointer;
  display: inline-block;
}

.Logo > svg {
  transition: var(--s_m_hover_transition);
  fill: var(--s_m_font_color);
}

@media only screen and (max-width: 1024px) {
  .Logo {
    margin-left: calc(50% - 16px);
    position: relative;
    top: -13px;
  }
}
