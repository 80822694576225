.Tile {
  min-width: 275px;
  min-height: 251px;
  cursor: pointer;
}

.Image {
  width: 100%;
  height: auto;
}
