.About {
}

.About > .AboutContent {
  row-gap: 15px;
}

.AboutHero {
  margin-top: 120px;
  margin-bottom: 120px;
}

.HeroSection {
  justify-content: space-between;
}

.RightTextAlign {
  text-align: right;
}

.LeftTextAlign {
  text-align: left;
}

.Cell {
}

@media only screen and (max-width: 1024px) {
  .AboutHero {
    margin-top: 100px;
    margin-bottom: 100px;
    row-gap: 50px;
  }
}
