.Grid4 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 70px;
}
.Cell {
  width: calc(50% - 35px);
  height: calc(50% - 35px);
}

@media only screen and (max-width: 1024px) {
  .Grid4 {
    flex-direction: column;
    row-gap: 50px;
  }
  .Cell {
    width: 100%;
  }
}
