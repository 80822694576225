.Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 700px;
  background-color: white;
}

.Wrapper > div:first-child {
  position: relative;
  height: 100%;
  flex: 1;
  margin: 0;
}

.Video {
  width: 90% !important;
  height: 700px !important;
  object-fit: cover;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10rem auto;
}

.CarouselBackground {
  position: absolute;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1024px) {
  .Wrapper {
    min-height: auto;
    height: auto;
  }

  .Video {
    margin: 2rem auto;
    height: 200px !important;
    width: 75% !important;
  }
}
