.CarouselControls {
  position: relative;
  top: -5vw;
  display: flex;
  max-width: 450px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.Arrow {
  z-index: 2;
  cursor: pointer;
  display: block;
  display: "inline-block";
  margin: 0 15px 0 15px;
  height: 20px;
  width: auto;
}

.ArrowNext {
}

.ArrowPrev {
}

.CarouselItemIndicator {
  margin: 0 5px 0 5px;
  cursor: pointer;
  width: 20px;
  height: auto;
}

@media only screen and (max-width: 1024px) {
  .Arrow {
    padding: 10px 10px 10px 10px;
  }

  .CarouselItemIndicator {
    padding: 10px 0px 10px 0px;
  }
  .CarouselControls {
    top: -5vw;
  }
}
