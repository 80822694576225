.DividerHero {
  display: flex;
  width: 100%;
}
.Left {
  padding: 0 60px 0 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
}
.Right {
  width: 100%;
}
.Divider {
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .DividerHero {
    flex-direction: column;
    row-gap: 70px;
  }
  .Left {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0 10px 0;
  }
}
