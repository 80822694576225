.Badge {
  height: 11.5vw;
  width: 11.5vw;
}
.Badge > svg {
  height: 11.5vw;
  width: 11.5vw;
  transition: var(--s_m_hover_transition);
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .Badge > svg:hover {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 1024px) {
  .Badge {
    height: 30vw;
    width: 30vw;
  }
  .Badge > svg {
    height: 30vw;
    width: 30vw;
  }
}
