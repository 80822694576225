.ImageHero {
  display: flex;
}

.HeaderContent a {
  color: var(--s_m_font_color);
}

.TextContent a {
  color: var(--s_m_font_color);
}

.HeaderContent a:hover {
  color: var(--s_m_hover_color);
}
.TextContent a:hover {
  color: var(--s_m_hover_color);
}
.Image {
  width: 100%;
  height: 100%;
}
