@import "open-props/style";
@import url("./fonts.css");

/* 
  * Define custom props accessible to the whole app in the body selector
  * throughout the stylesheets you will see use of custom props with the prefix `--s_m_{prop_name}`
*/
body {
  /* Defaults = Dark Theme*/
  --s_m_title_font: PPNeueMontreal-Bold;
  --s_m_content_font: PolySans;
  --s_m_menu_font: PPNeueMontreal-Bold;
  --s_m_mobile_breakpoint: 1024px;
  --s_m_background_color: var(--theme_dark_bg_color);
  --s_m_font_color: var(--theme_dark_color);
  --s_m_hover_color: var(--theme_dark_color);
  --s_m_hover_transition: all 0.3s ease-in-out;
  --s_m_theme_transition: all 0.7s ease-in-out;
  --s_m_theme_transition_delay: 0.2s;
  --s_m_page_h_padding: 16.93vw;
  --s_m_page_max_width: calc(100vw - var(--s_m_page_h_padding) * 2);
  --s_m_overlay_color: var(--theme_dark_overlay_color);
  --s_m_overlay_gradient_color: var(--theme_dark_overlay_gradient_color);
  --s_m_home_hero_h_padding: 8.72vw;
  --s_m_menu_h_margin: calc(var(--s_m_home_hero_h_padding) + 40px);
  --s_m_menu_half_circle_w: 90vw;
  --s_m_menu_half_circle_h: calc(var(--s_m_menu_half_circle_w) / 2);
  --s_m_menu_drop_shadow_fliter: drop-shadow(3px 3px 4px rgb(0 0 0 / 0.2));

  margin: 0;
  height: 100%;
  min-height: 1000px;
}

/* 
  * Set values of the theme custom props in :root selector 
  * Custom props with the selector `--theme_${prop name}` 
  * are meant to be used only for boilerplate code to map the values to the `--s_m_{prop name}`
  * which are intended to be used when styling the components.
  * You shouldn't be using `--theme_{theme color}_{prop_name}` in any of the component stylesheets.
*/
:root {
  /* Dark */
  --theme_dark_bg_color: black;
  --theme_dark_color: white;
  --theme_dark_color_hover: #757572;
  --theme_dark_dark_show_opacity: 1;
  --theme_dark_overlay_color: rgba(0, 0, 0, 0.7);
  --theme_dark_overlay_gradient_color: rgba(0, 0, 0, 0);

  /* Light */
  --theme_light_bg_color: white;
  --theme_light_color: #111111;
  --theme_light_color_hover: #757572;
  --theme_light_overlay_color: rgba(255, 255, 255, 0.7);
  --theme_light_overlay_gradient_color: rgba(255, 255, 255, 0);
}

/* Class dark maps the theme specific values to the globally accesible props defined in body selector */
.dark {
  --s_m_background_color: var(--theme_dark_bg_color);
  --s_m_font_color: var(--theme_dark_color);
  --s_m_hover_color: var(--theme_dark_color_hover);
  --s_m_overlay_color: var(--theme_dark_overlay_color);
  --s_m_overlay_gradient_color: var(--theme_dark_overlay_gradient_color);
}

/* Class light maps the theme specific values to the globally accesible props defined in body selector */
.light {
  --s_m_background_color: var(--theme_light_bg_color);
  --s_m_font_color: var(--theme_light_color);
  --s_m_hover_color: var(--theme_light_color_hover);
  --s_m_overlay_color: var(--theme_light_overlay_color);
  --s_m_overlay_gradient_color: var(--theme_light_overlay_gradient_color);
}

/* Class theme-transition is used for animations of the svgs most of them use this class inline in their respective files */
.theme-transition {
  transition: var(--s_m_theme_transition);
  transition-delay: var(--s_m_theme_transition_delay);
}

.theme-transition > * {
  transition: var(--s_m_theme_transition);
  transition-delay: var(--s_m_theme_transition_delay);
}

.theme-transition > path {
  transition: var(--s_m_theme_transition);
}

.theme-svg-white {
  transition: var(--s_m_hover_transition);
  fill: var(--s_m_font_color);
}
.theme-svg-black {
  transition: var(--s_m_hover_transition);
  fill: var(--s_m_background_color);
}

.theme-hover:hover {
  transition: var(--s_m_hover_transition);
}

.theme-hover:hover .theme-svg-white {
  fill: var(--s_m_hover_color);
}

.theme-hover:hover circle {
  stroke: var(--s_m_hover_color);
  stroke-width: 3;
}

html {
  height: 100%;
}

div#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 1024px) {
  body {
    --s_m_home_hero_h_padding: 2vw;
    --s_m_menu_h_margin: 5vw;
    --s_m_page_h_padding: 15vw;
    --s_m_menu_half_circle_h: 80vh;
  }
}

@media only screen and (orientation: landscape) {
  body {
    --s_m_menu_half_circle_h: 80vw;
  }
}
