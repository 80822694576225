.BadgesHero {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-around;
  gap: 2.099vw 1vw;
}

@media only screen and (max-width: 1024px) {
  .BadgesHero {
    align-items: center;
    justify-content: center;
    gap: 7vw 7vw;
  }
}
