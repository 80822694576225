.SocialIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}

.Facebook {
}

.Instagram {
}
