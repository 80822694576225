.CarouselItem {
}

.CarouselItem > section {
  position: relative;
  top: -7.2vw;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.CarouselLogo {
  height: 20vw;
  width: auto !important;
  position: absolute !important;
  top: 3vw;
  left: var(--s_m_page_h_padding);
}

.CarouselItem h1 {
  color: var(--s_m_font_color);
  margin: 0 0 11px 0;
}

.CarouselItem {
  text-align: left;
  color: var(--s_m_font_color);
}

.CarouselImage {
  height: 40vw;
}

.CarouselItem a {
  color: #757572;
  cursor: pointer;
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .CarouselItem a:hover {
    color: var(--s_m_font_color);
  }
}
@media only screen and (max-width: 1024px) {
  .CarouselItem {
    color: var(--s_m_font_color);
  }

  .CarouselItem h1 {
    color: var(--s_m_font_color);
  }

  .CarouselLogo {
    display: none !important;
  }

  .CarouselItem > section {
    top: -12vw;
  }
}
