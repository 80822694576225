.TextWithHeader {
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.textWrapperContentClass {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 3rem;
}

.textWrapperContentClass > div {
  flex: 1;
}

@media only screen and (max-width: 1024px) {
  .textWrapperContentClass {
    flex-direction: column;
  }
}
