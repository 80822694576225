.AboutHero {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 75px;
}
.Left {
  width: 100%;
}
.Right {
  width: 100%;
}
.Plus {
  fill: var(--s_m_font_color);
  width: 150px;
  height: auto;
}

@media only screen and (max-width: 1024px) {
  .AboutHero {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 70px;
    text-align: center;
  }

  .Left {
    border: none;
    padding: 0;
    width: 100%;
  }

  .Right {
    border: none;
    padding: 0;
    width: 100%;
  }

  .Plus {
    width: 70px;
  }
}
