.TextWithHeader {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.TextWithHeader p {
  margin: 0;
  padding: 0;
}
