.Wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  width: 33%;
  cursor: pointer;
}
.Icon {
  width: 50px;
  height: auto;
}
.CheckMark {
  fill: var(--s_m_font_color);
}

@media only screen and (max-width: 1024px) {
  .Wrapper {
    width: 100%;
  }
}
