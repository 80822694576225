.ServiceListItemContainerLeft {
  display: flex;
  flex-direction: row;
  margin-bottom: 91px;
  align-items: center;
}

.ServiceListItemContainerRight {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: 91px;
}

.itemIconLeft > svg {
  transition: var(--s_m_hover_transition);
  transform: scale(1);
  width: 12.5vw;
  height: 12.5vw;
  cursor: pointer;
  margin-right: 73px;
}

.itemIconContainer {
  display: flex;
  align-items: center;
}

.itemIconRight > svg {
  transition: var(--s_m_hover_transition);
  transform: scale(1);
  cursor: pointer;
  margin-left: 73px;
  width: 17.229vw;
  height: 17.229vw;
}

.itemIconLarge > svg {
  width: 15.229vw;
  height: 15.229vw;
}

.itemIconNormal > svg {
  width: 13.021vw;
  height: 13.021vw;
}

.title {
  margin-bottom: 30px;
}

.description {
}

.infoContainer {
}

@media only screen and (max-width: 1024px) {
  .ServiceMobileHero {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 40px;
  }

  .ServiceListItemContainerLeft {
    composes: ServiceMobileHero;
  }

  .ServiceListItemContainerRight {
    composes: ServiceMobileHero;
  }

  .itemIconLarge > svg {
    width: 147px;
    height: 147px;
    margin: 0 0 0 0;
  }

  .itemIconNormal > svg {
    width: 147px;
    height: 147px;
    margin: 0 0 0 0;
  }

  .itemIconRight > svg {
    width: 147px;
    height: 147px;
    margin: 0 0 0 0;
  }
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .itemIconLarge > svg:hover {
    transform: scale(1.1);
  }

  .itemIconNormal > svg:hover {
    transform: scale(1.1);
  }

  .itemIconRight > svg:hover {
    transform: scale(1.1);
  }

  .itemIconLeft > svg:hover {
    transform: scale(1.1);
  }
}
