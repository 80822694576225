.Footer {
  position: relative;
  width: 100vw;
  height: auto;
  background-image: url("../../assets/FooterBG.png");
  display: flex;
  justify-content: space-between;
  margin-bottom: 0 !important;
  padding-top: 100px;
  padding-bottom: 100px;
}

:global(.dark) .FooterLeft > *,
:global(.dark) .FooterRight > * {
  color: var(--theme_dark_color);
}

:global(.light) .FooterLeft > *,
:global(.light) .FooterRight > * {
  color: var(--theme_light_color);
}

.Copy {
  display: flex;
  column-gap: 10px;
}

.Copy > a {
  color: var(--s_m_font_color);
}

.Copy > a:hover {
  color: var(--s_m_hover_color);
}

.Footer .SocialIcons {
  width: 100%;
  margin-top: 0px;
  justify-content: flex-start;
}

.Logo {
  transition: var(--s_m_theme_transition);
  fill: var(--s_m_font_color);
  width: 15vw;
}

.Overlay {
  transition: var(--s_m_theme_transition);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--s_m_overlay_color);
}

.Footer > section {
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.FooterLeft {
  width: 380px;
  padding-left: var(--s_m_home_hero_h_padding);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.FooterRight {
  width: 50%;
}

.FooterRight > img {
  margin-bottom: 33px;
  width: 100%;
  height: auto;
}

.FooterRight > .FooterDetails {
  display: flex;
  column-gap: 13px;
}

.Footer h1 {
  margin: 0 0 30px 0;
}

.FooterDetails {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.FooterDetailsContact {
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  font-size: 20px;
}

.OfficeLocation {
  position: relative;
  top: 5px;
}

@media only screen and (max-width: 1024px) {
  .Footer {
    flex-direction: column;
    row-gap: 85px;

    padding-top: 50px;
    padding-bottom: 50px;
  }
  .Footer h1 {
    overflow-wrap: break-word;
    font-size: 90px;
    line-height: 90px;
  }
  .FooterLeft {
    margin-left: var(--s_m_page_h_padding);
    margin-right: var(--s_m_page_h_padding);
    max-width: var(--s_m_page_max_width);
    width: auto;
    padding: 0;
  }
  .FooterRight {
    margin-left: var(--s_m_page_h_padding);
    margin-right: var(--s_m_page_h_padding);
    max-width: var(--s_m_page_max_width);
    padding: 0;
    width: 100%;
  }
  .FooterRight > img {
    display: none;
  }

  .FooterRight > .FooterDetails {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .FooterDetailsContact {
    font-size: 16px;
    column-gap: 15px;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 5px;
  }

  .SocialIcons {
    flex-direction: row;
  }

  .Copy {
    display: flex;
    flex-direction: column;
  }
  .Logo {
    width: 50vw;
  }
}
