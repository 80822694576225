.CaseStudyHero {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
}

.CaseStudyHero a {
  color: inherit;
}

.CaseStudyHero > section {
  position: relative;
  top: -7.2vw;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  row-gap: 10px;
}
.CarouselLogo {
  height: 20vw;
  width: auto !important;
  position: absolute !important;
  top: 3vw;
  left: var(--s_m_page_h_padding);
}
.Description {
  position: relative;
}
.Description > a {
  transition: var(--s_m_hover_transition);
  color: #757572;
}
.HideReadMore > a {
  display: none;
}
.ServiceIcoWrapper {
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  gap: 25px 25px;
  justify-content: center;
}

.ServiceIcon > svg {
  width: 50px;
  height: 50px;
}

.ImageHero2 > img {
  width: 38.84vw;
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .Description > a:hover {
    color: var(--s_m_font_color);
  }
}

@media only screen and (max-width: 1024px) {
  .CaseStudyHero {
    height: auto;
  }

  .Heading {
    font-size: 40px;
    line-height: 110%;
  }
  .Description {
    font-size: 16px;
    line-height: 20px;
  }

  .CarouselLogo {
    display: none;
  }

  .CaseStudyHero > section {
    top: -12vw;
  }

  .ServiceIcoWrapper {
    justify-content: flex-start;
  }
}
