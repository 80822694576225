.Home {
}

.Logo {
  margin-left: calc(50% - 24px);
  margin-bottom: 20px;
  margin-top: 35px;
  cursor: pointer;
  display: inline-block;
}

.Logo > svg {
  transition: var(--s_m_hover_transition);
  fill: var(--s_m_font_color);
}

.HeroHeader {
  margin-left: var(--s_m_home_hero_h_padding);
  margin-right: var(--s_m_home_hero_h_padding);
}

.TextContentBlock {
  margin-top: 120px;
  row-gap: 15px;
}

.TextSectionHeader {
  width: 70%;
}

.TextSectionContent {
  width: 70%;
}

.AboutUsBtn {
  margin-top: 30px;
  margin-bottom: 120px;
}

.ServiceHeroWrapper {
  margin-top: 70px;
  margin-bottom: 100px;
}

.ServiceHero {
  column-gap: 75px;
  align-items: center;
  margin-bottom: 70px;
}

.ServiceHeroTextWrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.ServiceHeroIco > svg {
  transition: all 0.3s ease-out;
  transform: scale(1);
  width: 12.5vw;
  height: 12.5vw;
  cursor: pointer;
}

.OurServicesBtn {
}

.Badges {
  margin-top: 30px;
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .Logo:hover > svg {
    fill: var(--s_m_hover_color);
  }
  .ServiceHeroIco > svg:hover {
    transform: scale(1.1);
  }
}

@media only screen and (max-width: 1024px) {
  .Logo {
    margin-left: calc(50% - 16px);
    position: relative;
  }
  .TextContentBlock {
    margin-top: 100px;
  }
  .AboutUsBtn {
    margin-bottom: 100px;
  }
  .ServiceHero {
    margin-bottom: 50px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .ServiceHeroTextWrapper {
    width: 100%;
  }
  .ServiceHeroIco > svg {
    width: 148px;
    height: 148px;
  }

  .OurServicesBtn {
    margin-top: 30px;
  }

  .TextSectionHeader {
    width: 100%;
  }

  .TextSectionContent {
    width: 100%;
  }

  .ServiceHeroWrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    row-gap: 15px;
  }

  .Badges {
    margin-top: 15px;
  }
}
