@font-face {
  font-family: PPNeueMontreal-Regular;
  src: url("../assets/PP_Neue_Montreal/PPNeueMontreal-Regular.ttf");
}

@font-face {
  font-family: PPNeueMontreal-Bold;
  src: url("../assets/PP_Neue_Montreal/PPNeueMontreal-Bold.ttf");
}

@font-face {
  font-family: PPNeueMontreal-Medium;
  src: url("../assets/PP_Neue_Montreal/PPNeueMontreal-Medium.ttf");
}

@font-face {
  font-family: PolySans;
  src: url("../assets/PolySans/PolySans-Slim.woff");
}

@font-face {
  font-family: CanelaLight;
  src: url("../assets/canela/Canela-Light.otf");
}

h1,
p {
  margin: 0 0 0 0;
  width: auto;
}
