.Btn {
  transition: background-color 0.25s linear, border-color 0.25s linear;
  cursor: pointer;
  background-color: transparent;
  color: var(--s_m_font_color);
  font-size: 22px;
  line-height: 25px;
  border: 2px solid #999999;
  border-radius: 110px;
  height: 80px;
  padding: 10px 40px 10px 40px;
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .Btn:hover {
    background-color: var(--s_m_hover_color);
    border: 2px solid var(--s_m_hover_color);
  }
}

.Btn:active {
  background: rgba(84, 84, 84, 0.5);
  border: 6px solid #000000;
}

@media only screen and (max-width: 1024px) {
  .Btn {

  }
}
