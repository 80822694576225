.ImageWithOverlay {
  position: relative;
}

.ImageWithOverlay > img {
  width: 100%;
  height: auto;
}

.ImageOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: var(--s_m_theme_transition);
  background: linear-gradient(
    180deg,
    var(--s_m_overlay_gradient_color) 58.44%,
    var(--s_m_background_color) 83.65%
  );
}
