.Page {
  overflow-x: hidden;
  min-height: 100vh;
}

.FallBackElement {
  padding: 25px;
  margin: 25px;
  border: 1px solid var(--gray-7);
  text-align: center;
}

.SectionSpace {
  margin-bottom: 120px;
}

.ContentAlign {
  margin-left: var(--s_m_page_h_padding);
  /* margin-right: var(--s_m_page_h_padding); */
  max-width: var(--s_m_page_max_width);
}

.LeftAlign {
  margin-left: var(--s_m_page_h_padding);
}

.RightAlign {
  margin-right: var(--s_m_page_h_padding);
}

.CenterAlign {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.ThirdFourthAlign {
  margin-left: var(--s_m_page_h_padding);
  margin-right: calc(2 * var(--s_m_page_h_padding));
}

.AfterHeroFix {
  margin-top: 0 !important;
}

.HomeLink {
  margin-top: 60px;
  width: 270px;
  position: relative;
  left: 22px;
}

.LogoWordMark {
  fill: var(--s_m_font_color);
  transition: var(--s_m_hover_transition);
}

.Footer {
  margin-bottom: 0;
}

.DividerSpace {
  margin-top: 200px;
  margin-bottom: 200px;
}

.Divider {
  transition: var(--s_m_theme_transition);
  fill: var(--s_m_font_color);
  width: 40px;
  height: auto;
}

.NoUnderline {
  text-decoration: none;
}

.PullQuote {
  font-family: CanelaLight;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: -0.01em;
}

.ScaledImage {
  display: flex;
  width: 100%;
  height: 100%;
  transform: scale(0.7);
  -webkit-transform: scale(0.7);
  -moz-trandform: scale(0.7);
}

.tenPercent {
  transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -moz-trandform: scale(0.6);
}

.Jumbo {
  font-family: var(--s_m_title_font);
  font-weight: 700;
  font-size: 90px;
  line-height: 100px;
  letter-spacing: -0.01em;
}
:global(.light) .Jumbo {
  --s_m_font_color: #111111;
}
.h1 {
  font-family: var(--s_m_title_font);
  font-weight: 700;
  font-size: 60px;
  line-height: 75px;
  letter-spacing: -0.01em;
}
:global(.light) .h1 {
  --s_m_font_color: #111111;
}
.h2 {
  font-family: var(--s_m_title_font);
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.01em;
}
:global(.light) .h2 {
  --s_m_font_color: #111111;
}
.h3 {
  font-family: var(--s_m_content_font);
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.05em;
}
:global(.light) .h3 {
  --s_m_font_color: #111111;
}
.p1 {
  font-family: var(--s_m_content_font);
  font-weight: 300;
  font-size: 26px;
  line-height: 34px;
  letter-spacing: 0.01em;
}

:global(.light) .p1 {
  --s_m_font_color: #555555;
}

:global(.dark) .p1 {
  --s_m_font_color: #a6a6a6;
}
.p2 {
  font-family: var(--s_m_content_font);
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.01em;
}

:global(.light) .p2 {
  --s_m_font_color: #555555;
}

:global(.dark) .p2 {
  --s_m_font_color: #a6a6a6;
}

.p3 {
  font-family: var(--s_m_content_font);
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
}

:global(.light) .p3 {
  --s_m_font_color: #555555;
}

:global(.dark) .p3 {
  --s_m_font_color: #a6a6a6;
}

.Scroll::-webkit-scrollbar {
  display: none;
}

.NoScroll {
  overflow: hidden;
}

.LogoBunny {
  width: 46px;
  transition: var(--s_m_hover_transition);
}

.PageHeader {
  margin-top: 120px;
  width: 100%;
}

.FillContentImage {
  --s_m_case_study_fill_image_width: 86.45vw;
  width: var(--s_m_case_study_fill_image_width);
  margin-left: calc((100vw - var(--s_m_case_study_fill_image_width)) / 2);
  margin-right: calc((100vw - var(--s_m_case_study_fill_image_width)) / 2);
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .LogoWordMark:hover {
    fill: var(--s_m_hover_color);
  }

  .LogoBunny:hover {
    fill: var(--s_m_hover_color);
  }
}

@media only screen and (max-width: 1024px) {
  .Page {
  }
  .LogoBunny {
    width: 33px;
    height: 100%;
  }

  .Divider {
    margin-top: 150px;
    margin-bottom: 150px;
  }

  .Jumbo {
    font-size: 58px;
    line-height: 58px;
    letter-spacing: -0.01em;
  }

  .h1 {
    font-size: 38px;
    line-height: 46px;
    letter-spacing: -0.01em;
  }
  .h2 {
    font-size: 28px;
    line-height: 30px;
  }
  .h3 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.01em;
  }
  .p1 {
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0.01em;
  }
  .p2 {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
  }

  .p3 {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
  }

  .HomeLink {
    margin-top: 60px;
    width: 180px;
    left: 2px;
  }

  .LogoWordMark {
  }

  .PageHeader {
    margin-top: 100px;
  }

  .SectionSpace {
    margin-bottom: 100px;
  }
}
