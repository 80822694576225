.HeroHeader {
}

.DownArrow {
  margin-left: auto;
  margin-right: auto;
  display: block;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-delay: 1s;
  transition: opacity 1s linear;
  opacity: 1;
  position: absolute;
  bottom: -40px;
  left: calc(50% - 15px);
  fill: var(--s_m_font_color);
}

.Background {
}

.Background > svg {
  max-width: 100%;
  height: auto;
}

.HeroLogo {
  --s_m_hero_logo_w: 27vw;
  --s_m_hero_logo_h: 12.86vw;
  position: absolute;
  top: calc(50% - var(--s_m_hero_logo_h) / 2);
  left: calc(0.9vw + 50% - var(--s_m_hero_logo_w) / 2);
  height: var(--s_m_hero_logo_h);
  width: var(--s_m_hero_logo_w);
  transition: all 1.5s ease-out;
  transition-property: opacity, transform;
  transform: translateY(0px);
  opacity: 1;
  fill: var(--s_m_font_color);
}

.Foreground {
}

.ForegroundOffScreen > .HeroLogo {
  transform: translateY(100px);
  opacity: 0;
}

.BackgroundVector {
  fill: var(--s_m_font_color);
  fill-opacity: 0.1;
  transition: all 1.5s ease-out;
  transform: translateY(0px);
}

.BackgroundVectorOffScreen {
  fill-opacity: 0;
  transform: translateY(-100px);
}

.PlusBtn {
  display: inline-block;
}

.Foreground > h1 {
  margin: 0 0 23px 0;
  padding: 0;
}

.Foreground > h1 {
  margin: 0 0 23px 0;
  padding: 0;
}

.Foreground > h1 > .PlusBtn {
  position: relative;
  top: -10px;
}

.fadeOut {
  opacity: 0;
}

.bounce {
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
}

@keyframes bounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  30% {
    transform: scale(0.95, 1.05) translateY(-20px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-10px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}

@media only screen and (max-width: 1024px) {
  .HeroHeader {
    height: auto;
  }
  .HeroLogo {
    --s_m_hero_logo_w: 54vw;
    --s_m_hero_logo_h: 25.72vw;
  }

  .Background > svg {
    width: 100vw;
  }
  .DownArrow {
    left: ;
  }
}
