.LookInsideWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: var(--s_m_hover_transition);
  transform: scale(1);
  cursor: pointer;
}

.PopUpLink {
  cursor: pointer;
  text-decoration: underline;
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  .LookInsideWrapper:hover {
    transform: scale(1.05);
    color: var(--s_m_hover_color);
  }
}
