.List {
  position: relative;
  --s_m_tile_list_column_gap: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  min-height: 251px;
}

.ListItem {
  width: calc(33% - var(--s_m_tile_list_column_gap));
  --transition-duration: 0.3s;
}

.grow {
  animation-duration: var(--transition-duration);
  transition-timing-function: var(--ease-out-2);
}

.grow.hidden {
  animation: var(--animation-scale-down) forwards;
  animation-duration: var(--transition-duration);
  transition-timing-function: var(--ease-out-2);
}

.fadeInOut {
  transition-duration: var(--transition-duration);
  transition-property: visibility, opacity;
  transition-delay: 0;
  transition-timing-function: var(--ease-out-2);
}
.fadeInOut.hidden {
  visibility: hidden;
  opacity: 0;
  transition-duration: var(--transition-duration);
  transition-property: opacity, visibility;
  transition-delay: var(--transition-duration);
  transition-timing-function: var(--ease-out-2);
}

.NoResults {
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 1024px) {
  .ListItem {
    width: 100%;
  }
  .List {
    flex-direction: column;
    row-gap: 15px;
  }
}

/* Only if the device is capable of hovering */
@media (hover: hover) and (pointer: fine) {
  @keyframes scale-up-slightly {
    to {
      transform: scale(1.03);
    }
  }
  .ListItem:hover {
    animation: scale-up-slightly forwards;
    animation-duration: var(--transition-duration);
    transition-timing-function: var(--ease-out-2);
  }
}
