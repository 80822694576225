.CaseStudyFilter {
}

.FilterButton {
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  align-items: center;
  cursor: pointer;
}

.Arrow {
  fill: var(--s_m_font_color);
  transition: all 0.5s ease-in-out;
  transition-property: transform;
}

.ArrowClosed {
  transform: rotate(0deg);
}

.ArrowOpen {
  transform: rotate(180deg);
}

.OptionsOverlay {
  display: flex;
  flex-wrap: wrap;
  transition: height 0.5s var(--ease-in-5), opacity 0.25s 0.2s var(--ease-in-5);
  z-index: 1;
  row-gap: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.OptionsOverlayClosed {
  height: 0;
  opacity: 0;
}

.OptionsOverlayOpen {
  height: 200px;
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .OptionsOverlay {
    display: flex;
    flex-wrap: wrap;
  }

  .OptionsOverlayOpen {
    height: 700px;
    opacity: 1;
  }
}
